// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-keyseller-graphql-js": () => import("../src/templates/keyseller-graphql.js" /* webpackChunkName: "component---src-templates-keyseller-graphql-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-charts-js": () => import("../src/pages/charts.js" /* webpackChunkName: "component---src-pages-charts-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keyseller-js": () => import("../src/pages/keyseller.js" /* webpackChunkName: "component---src-pages-keyseller-js" */),
  "component---src-pages-topseller-js": () => import("../src/pages/topseller.js" /* webpackChunkName: "component---src-pages-topseller-js" */)
}

